.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  background-color: #002850;
  color: #ffffff;
}

.custom-info tbody + tbody,
.custom-info td,
.custom-info th,
.custom-info thead th {
  background-color: #a1c9f0;
  color: #000000;
}

.blank-row tbody + tbody,
.blank-row td,
.blank-row th,
.blank-row thead th {
  background-color: #ffffff;
  height: 30px;
}
